<template>
  <div v-loading="isLoading" class="product-card" :class="{ 'is-forsale': product.isForSale }">
    <div @mouseover="hover = true" @mouseleave="hover = false">
      <NewBaseCard>
        <div class="product-image-content">
          <router-link v-if="!productImgIsEmpty" :to="productRoute(product)" class="image-wrapper">
            <img :src="mainImageUrl" :alt="product.name" class="image" />
          </router-link>
          <router-link v-else :to="productRoute(product)" class="placeholder">
            <img :src="categoryImage(product.categoryName)" :alt="product.categoryName" class="placeholder-image" />
          </router-link>
          <div v-if="product.isForSale" class="for-sale-header">For sale in your store</div>
        </div>
        <div v-if="isMarketPlace" class="info-container marketplace">
          <div class="info-top">
            <a-tooltip>
              <template slot="title">
                <span> {{ product.name }}</span>
              </template>
              <p class="name">
                {{ product.name }}
              </p>
            </a-tooltip>
            <div class="prices">${{ productPricing.toFixed(2) }}</div>
            <div class="btn-marketplace">
              <BaseButton
                v-if="hover && myStockRoomProduct && product.inStock && !product.registrationRequired"
                class="sidebar-btn"
                type="secondary"
                @click="addOneToCart"
                @mouseover="hover = true"
                @mouseleave="hover = false"
              >
                Add To Cart
              </BaseButton>
              <BaseButton
                v-if="hover && myStockRoomProduct && !product.inStock"
                class="sidebar-btn"
                @mouseover="hover = true"
                @mouseleave="hover = false"
              >
                Out Of Stock
              </BaseButton>
            </div>
          </div>
        </div>
        <div v-else class="info-container">
          <div class="info-top">
            <div class="prices">
              <div class="product-pricing">
                <span class="title">Practice Price:</span>
                <span class="value">${{ productPricing.toFixed(2) }}</span>
              </div>
              <div class="charge-content">
                <div class="charge-value">
                  <span class="title">Patient Price:</span>
                  <span class="value">
                    <template v-if="!isEdit"> ${{ productCharge.toFixed(2) }} </template>
                    <BaseInput
                      v-else
                      :on-change-value="onChangeValue"
                      input-placeholder="Type a number"
                      :value="productChargePrice"
                      type="number"
                    />
                  </span>
                </div>
                <div class="action-items">
                  <template v-if="isEdit">
                    <!-- <a
                      class="save-edit-btn"
                      @click="saveCharge(product)"
                    ><a-icon
                      type="check"
                    /></a> -->
                    <a class="cancel-edit-btn" @click="cancelCharge(product)"><a-icon type="close" /></a>
                  </template>
                  <a v-else class="edit-btn" @click="() => (isEdit = true)"><a-icon type="edit" /></a>
                </div>
              </div>
            </div>
            <BaseButton
              v-if="hover && myStockRoomProduct && product.inStock && !product.registrationRequired"
              class="sidebar-btn"
              type="secondary"
              @click="addOneToCart"
              @mouseover="hover = true"
              @mouseleave="hover = false"
            >
              Add To Cart
            </BaseButton>
            <BaseButton
              v-if="hover && myStockRoomProduct && !product.inStock"
              class="sidebar-btn"
              @mouseover="hover = true"
              @mouseleave="hover = false"
            >
              Out Of Stock
            </BaseButton>
          </div>
          <div class="info-bottom">
            <a-row class="info-bottom--product-toggle">
              <a-col class="product-name" :xs="24" :sm="20" :lg="20" :xl="20">
                <a-tooltip placement="topRight">
                  <template slot="title">
                    <span> {{ product.name }}</span>
                  </template>
                  <p class="name">
                    {{ product.name }}
                  </p>
                </a-tooltip>
              </a-col>
              <ABaseButton
                v-if="isEdit"
                class="save-product-price"
                type="primary"
                button-text="SAVE"
                :handle-click="() => savePatientPrice(product)"
              />
              <a-switch
                v-else
                :checked="product.isForSale"
                :xs="24"
                :sm="4"
                :lg="4"
                :xl="4"
                :disabled="isHomeTestingKit"
                @change="toggleOnForSale"
              >
                <a-icon slot="checkedChildren" type="check" />
                <a-icon slot="unCheckedChildren" type="close" />
              </a-switch>
            </a-row>
          </div>
        </div>
      </NewBaseCard>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isEmpty, get } from "lodash";
import { findCategoryImage } from "@/utils/imagePlaceholder";
import NewBaseCard from "@/components/common/NewBaseCard.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import notification from "@/utils/notifications";
import BaseInput from "../../common/AntDesign/BaseInput.vue";
import ABaseButton from "../../common/AntDesign/BaseButton.vue";

export default {
  name: "ProductCard",
  components: {
    NewBaseCard,
    BaseButton,
    BaseInput,
    ABaseButton,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    storeProduct: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    myStockRoomProduct: {
      type: Boolean,
      required: true,
    },
    isMarketPlace: {
      type: Boolean,
      default: false,
    },
    itemsForSale: Array,
  },
  data() {
    return {
      added: false,
      active: false,
      hover: false,
      isEdit: false,
      isLoading: false,
      productCharge: 0,
      productChargePrice: 0,
    };
  },
  computed: {
    ...mapGetters({
      getOrgId: "getOrgId",
      getUserLoggedInStatus: "getUserLoggedInStatus",
      getProduct: "marketplaceProduct/getProduct",
      getAllCheckedProducts: "sellerStore/getAllCheckedProducts",
      getWholesaleTierPricingId: "marketplaceProduct/getWholesaleTierPricingId",
      getCurrentDomain: "sellerStore/getCurrentDomain",
      getStoreDetails: "sellerStore/getStoreDetails",
      getPageResults: "sellerStore/getPageResults",
      getCurrentUser: "getCurrentUser",
    }),
    productImgIsEmpty() {
      return isEmpty(this.product.images);
    },
    mainImageUrl() {
      const mainImage = this.product.images.find((i) => {
        return i.mainImage;
      });

      return get(mainImage, "imageLocation", "");
    },
    loggedIn() {
      return this.getUserLoggedInStatus;
    },
    productPricing() {
      const vendorPrice = get(this.product, "price", 0);
      return vendorPrice || 0;
    },
    isHomeTestingKit() {
      const { categorySlug, subcategorySlug } = this.product;
      return categorySlug === "medical-supplies" && subcategorySlug === "home-testing-kit";
    },
  },
  mounted() {
    const productCharge = get(this.product, "practicePrice", 0);
    this.productCharge = productCharge || 0;
    this.productChargePrice = this.productCharge;
  },
  methods: {
    ...mapActions({
      addToCart: "marketplaceSearch/addToCart",
      fetchProductById: "marketplaceProduct/fetchProductById",
      setEmptyCart: "setEmptyCart",
      addMinQtyToCart: "marketplaceSearch/addMinQtyToCart",
      updateStoreDetails: "sellerStore/updateStoreDetails",
      updateOrgPracticePrice: "sellerStore/updateOrgPracticePrice",
    }),
    ...mapMutations({
      updateProduct: "sellerStore/updateProduct",
      updatePracticeProduct: "sellerStore/updatePracticeProduct",
    }),
    checkProductAddedToStore(product) {
      return this.getAllCheckedProducts.filter((id) => {
        return product.id === id;
      });
    },
    categoryImage(categoryType) {
      return findCategoryImage(categoryType);
    },
    // ** NEED TO REFACTOR **
    async addOneToCart() {
      await this.fetchProductById(this.product.id);

      if (this.getProduct.minQtyForPurchase > 1) {
        await this.addMinQtyToCart({
          productId: this.product.id,
          productPricingId: this.product.productPricingId,
          qty: this.getProduct.minQtyForPurchase,
          productName: this.product.name,
        });
      } else {
        this.added = true;
        await this.addToCart({
          productId: this.product.id,
          productPricingId: this.product.productPricingId,
        })
          .then(() => {
            this.setEmptyCart(false);
            notification("success", `The product ${this.product.name} successfully added into the cart.`);
            setTimeout(() => {
              this.added = false;
            }, 1000);
          })
          .catch((error) => {
            notification("warning", error);
          });
      }
    },
    productRoute(product) {
      const { vin, slug, categorySlug } = product;
      return {
        name: "Product",
        params: { vin, slug, categorySlug },
      };
    },
    async savePatientPrice(product) {
      this.isLoading = true;
      const payload = {
        orgId: this.getCurrentDomain.organization.id,
        productId: product.id,
        userId: this.getCurrentUser.id,
        price: this.productChargePrice,
      };
      const result = await this.updateOrgPracticePrice(payload);
      this.updatePracticeProduct(result);
      this.productCharge = result.price;
      this.isLoading = false;
      this.isEdit = false;
    },
    cancelCharge() {
      this.productChargePrice = this.productCharge;
      this.isEdit = false;
    },
    async toggleOnForSale(value) {
      this.isLoading = true;
      const index = this.getPageResults.findIndex((item) => {
        return item.id === this.product.id;
      });

      this.$emit("updateProductToggle", { id: this.product.id, value });
      this.updateProduct({ index, value, productId: this.product.id });

      await this.updateStoreDetails({
        orgId: this.getOrgId,
        products: this.itemsForSale,
        domainName: this.getCurrentDomain.subDomain,
        commission: this.getStoreDetails.commission,
      });

      this.isLoading = false;
    },
    onChangeValue(evt) {
      this.productChargePrice = evt.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.save-product-price {
  font-size: 9px;
  height: 20px;
  width: 50px;
}
.btn-marketplace {
  display: flex;
  justify-content: center;
}
* {
  margin: 0;
  padding: 0;
}

.product-image-content {
  position: relative;
  .for-sale-header {
    width: 100%;
    background: #66c23a;
    text-align: center;
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 10px;
    position: absolute;
    top: 0;
  }
}

.ant-switch {
  width: 25%;
}
.store-tag {
  margin-left: auto;
  padding: 0px 5px;
}
.sidebar-btn {
  // margin-left: auto;
  padding: 8px;
}
.product-card {
  width: 220px;
  padding: 5px;

  // &.is-forsale {
  //   border: 1px solid #66c23a;
  // }
}
.image {
  width: 100%;
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
}
.placeholder {
  // width: 190px;
  height: 190px;
  background: $light-grey;
  display: flex;
  justify-content: center;
  align-items: center;
}
.placeholder-image {
  width: 75px;
}

.info-top,
.info-bottom {
  margin-top: 10px;
  overflow: hidden;
  .ant-row {
    display: flex;
    align-items: center;
    .product-name {
      width: 75%;
    }
    .checkbox {
      width: 25px;
      height: 15px;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
}
.name {
  color: $simpatra-blue;
  font-weight: 600;
  font-size: 15px;
  cursor: default;
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  height: auto;
  width: min-content;
}
.price {
  color: $simpatra-blue;
  font-size: 0.8em;
  position: absolute;
}
.add-to-cart {
  color: $simpatra-orange;
  background: none;
  border: none;
  font-size: 0.8em;
  padding: 10px;
  margin: -10px;
  &:hover {
    cursor: pointer;
    background-color: #feefea;
    border-radius: 4px;
  }
  &:focus {
    outline: none;
  }
}
.price-item {
  display: flex;
  justify-content: space-between;
  color: #000;
}

::v-deep {
  .charge-value {
    .value .ant-input {
      width: 50%;
      height: 21px;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #40a9ff;
      &:hover,
      &:focus,
      &:active {
        border-color: transparent;
        box-shadow: none;
      }
    }
    input {
      border-bottom: 1px solid #f05a28;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-radius: 0;
      width: 50%;
      height: 90%;
      box-shadow: none;
      padding: 0;
      text-align: center;
    }
  }
  .card {
    border: none;
    &:hover {
      border-color: #00000017;
      box-shadow: 0 2px 8px #00000017;
    }
  }
  .card-content {
    padding: 0;
  }
}

.info-top,
.info-bottom {
  .product-pricing,
  .charge-value {
    display: flex;
  }

  .charge-content {
    position: relative;
    .edit-btn,
    .save-edit-btn,
    .cancel-edit-btn {
      font-size: 13px;
      position: absolute;
      top: 0px;
    }

    .edit-btn,
    .cancel-edit-btn {
      right: 6%;
    }
  }

  .product-pricing,
  .charge-value {
    .title {
      width: 55%;
      text-align: end;
    }
    .value {
      margin-left: 15px;
      width: 45%;
      height: 21px;
    }
  }

  .action-items {
    a {
      padding: 4px;
      display: flex;
      border-radius: 50%;
      background: #80808014;
    }
    a:hover {
      background: #8080802b;
    }
  }
}

.card {
  border-radius: 0;
  .card-content {
    .image-wrapper {
      width: 100%;
      height: 190px;
      overflow: hidden;
      display: flex;
      justify-content: center;
    }

    .info-bottom {
      padding: 7px;
    }

    .info-bottom {
      height: 35px;
      background: #e5e5e5;
    }
  }
}

.info-container {
  &.marketplace {
    .info-top {
      padding: 0 0 10px 10px;
      .name {
        font-size: 0.8em;
        color: #374458;
        font-weight: 600;
      }
      .prices {
        color: #374458;
        font-size: 0.8em;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .info-top,
  .info-bottom {
    .product-name {
      width: 85%;
    }
    .checkbox.ant-col {
      width: 10%;
    }
  }

  .product-card {
    .image-wrapper,
    .placeholder {
      width: 160px !important;
      height: 120px;
      img {
        object-fit: fill;
      }
    }

    .info-container {
      height: 47px;
      .info-top {
        height: 45px;
      }

      .info-bottom {
        height: 17px;
      }

      .info-top,
      .info-bottom {
        height: 30px;
        // margin-top: 10px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        // .price {
        //   height: 20px;
        //   display: flex;
        //   align-items: center;
        // }
        .store-tag {
          height: 20px;
          width: 115px;
          font-size: 10px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
