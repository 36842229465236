<style lang="scss" scoped>
@import "../styles/OrderHistory.scss";
</style>
<template>
  <div id="">
    <div class="order-history">
      <div>
        <!-- <SearchInput class="patient-search" option-placeholder="Search Order" :handle-search="handleSearch" /> -->
        <Loading v-if="isLoading" :is-medium="true" />
        <template v-else>
          <div v-if="getOrderHistory.data.length > 0" class="order-history-top">
            <LocationOrder
              v-for="(order, index) in getOrderHistory.data"
              :key="order.purchaseId"
              :index="index"
              :order="order"
              class="location-order"
            />
          </div>
          <BaseNoData v-else class="empty"> Order not found </BaseNoData>
        </template>
      </div>
    </div>
    <Pagination
      v-if="totalPages > 1"
      :default-current-page="defaultCurrentPage"
      :page-size="pageSize"
      :total="total"
      :handle-pagination="handlePagination"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { get, isEmpty } from "lodash";
import LocationOrder from "@/components/marketplace/LocationOrder.vue";
import BaseNoData from "@/components/common/BaseNoData.vue";
// import SearchInput from "@/components/shared-components/SearchInput.vue";
import Loading from "@/components/common/AntDesign/Loading.vue";
import Pagination from "@/components/shared-components/Pagination.vue";

export default {
  name: "OrderHistory",
  components: {
    LocationOrder,
    BaseNoData,
    // SearchInput,
    Pagination,
    Loading,
  },
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      defaultCurrentPage: 1,
      pageSize: 10,
      total: 0,
      totalPages: 0,
      searchOrder: "",
    };
  },
  computed: {
    ...mapGetters("ordersModule", ["getOrderHistory"]),
  },
  async created() {
    const { currentPage } = this.getOrderHistory.paginationDetails;
    if (currentPage > 0 || isEmpty(this.getOrderHistory.data)) {
      await this.fetchOrderHistory({ locationId: this.locationId, page: this.defaultCurrentPage - 1 });
    }
    this.initOrderHistory();
  },
  methods: {
    ...mapActions("orderHistoryModule", [
      "fetchLocationPurchases",
      "clearLocationPurchases",
      "nextPage",
      "prevPage",
      "commitPage",
    ]),
    ...mapActions("ordersModule", ["fetchOrderHistory"]),
    async handleSearch(queryString = "") {},
    async handlePagination(e) {
      this.isLoading = true;
      this.defaultCurrentPage = e;
      await this.fetchOrderHistory({ locationId: this.locationId, page: this.defaultCurrentPage - 1 });
      this.initOrderHistory();
    },
    initOrderHistory() {
      this.pageSize = get(this.getOrderHistory.paginationDetails, "pageSize", 0);
      this.total = get(this.getOrderHistory.paginationDetails, "totalData", 0);
      this.totalPages = get(this.getOrderHistory.paginationDetails, "totalPages", 0);
      this.isLoading = false;
    },
  },
};
</script>
