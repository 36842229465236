<template>
  <div class="product-card relative">
    <div v-if="is503aProduct" class="ribbon-wrapper">
      <div class="ribbon">503A Product</div>
    </div>
    <div @mouseover="hover = true" @mouseleave="hover = false">
      <NewBaseCard class="item" @list-card-click="routeToProductDetails()">
        <a-row class="w-full">
          <a-col :sm="16" :lg="16" :xl="isLoggedIn && !is503aProduct ? 18 : 17">
            <router-link :to="productRoute(product)" class="link">
              <input
                v-if="storeProduct"
                v-model="checkedProducts"
                type="checkbox"
                class="checkbox"
                :value="product.id"
                @click.stop
              />
              <div v-if="mainImageUrl" class="image-wrapper">
                <img :src="mainImageUrl" :alt="product.name" class="image" />
              </div>
              <div v-else class="placeholder">
                <img :src="categoryImage(product.categoryName)" :alt="product.categoryName" class="placeholder-image" />
              </div>
              <div v-if="product.price || isStoreProduct()" class="info-container">
                <div class="info-top">
                  <p
                    class="name"
                    :class="{
                      'out-of-stock': !product.inStock,
                    }"
                  >
                    {{ product.name }}
                  </p>
                  <p v-if="!product.inStock" class="out-of-stock-text">Out of stock</p>
                </div>
              </div>
            </router-link>
          </a-col>
          <a-col class="no-link" :lg="8" :xl="isLoggedIn && !is503aProduct ? 6 : 7">
            <div v-if="is503aProduct && isLoggedIn" class="product-card--prescription-info">
              This product is only available on the Prescription page in the Patient Chart
            </div>
            <ProductAddToCartFromList v-else :product="product" class="add-to-cart-list" />
          </a-col>
        </a-row>
      </NewBaseCard>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Message } from "element-ui";
import { findCategoryImage } from "@/utils/imagePlaceholder";
import NewBaseCard from "@/components/common/NewBaseCard.vue";
import ProductAddToCartFromList from "@/components/marketplace/product/ProductAddToCartFromList.vue";

export default {
  name: "ProductListCard",
  components: {
    NewBaseCard,
    ProductAddToCartFromList,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    storeProduct: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    myStockRoomProduct: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      added: false,
      active: false,
      hover: false,
    };
  },
  computed: {
    ...mapGetters({
      getUserLoggedInStatus: "getUserLoggedInStatus",
      getProduct: "marketplaceProduct/getProduct",
      getAllCheckedProducts: "sellerStore/getAllCheckedProducts",
      getWholesaleTierPricingId: "marketplaceProduct/getWholesaleTierPricingId",
      isLoggedIn: "isLoggedIn",
    }),
    checkedProducts: {
      get() {
        return this.getAllCheckedProducts;
      },
      set(selectedProducts) {
        this.$emit("checkedProductList", selectedProducts);
      },
    },
    mainImageUrl() {
      const mainImage = this.product.images.find((i) => {
        return i.mainImage;
      });
      if (mainImage !== undefined) {
        return mainImage.imageLocation;
      }
      return null;
    },
    loggedIn() {
      return this.getUserLoggedInStatus;
    },
    productPricing() {
      if (this.product.productPricing !== undefined) {
        const data = this.product.productPricing.filter((pricing) => {
          if (pricing.pricingTier.name === "Retail Pricing") {
            return pricing;
          }
          return [];
        });
        if (data.length > 0) {
          return data[0].finalPrice.toFixed(2);
        }
        return "";
      }
      return this.product.price.toFixed(2);
    },
    is503aProduct() {
      return this.product.subcategorySlug === "503A";
    },
  },
  created() {},
  methods: {
    ...mapActions({
      addToCart: "marketplaceSearch/addToCart",
      fetchProductById: "marketplaceProduct/fetchProductById",
      setEmptyCart: "setEmptyCart",
      addMinQtyToCart: "marketplaceSearch/addMinQtyToCart",
    }),
    checkProductAddedToStore(product) {
      return this.getAllCheckedProducts.filter((id) => {
        return product.id === id;
      });
    },
    isStoreProduct() {
      if (this.product.productPricing !== undefined) {
        if (this.product.productPricing[0] !== undefined) {
          return true;
        }
        return false;
      }
      return false;
    },
    categoryImage(c) {
      return findCategoryImage(c);
    },
    async addOneToCart() {
      await this.fetchProductById(this.product.id);

      if (this.getProduct.minQtyForPurchase > 1) {
        await this.addMinQtyToCart({
          productId: this.product.id,
          productPricingId: this.product.productPricingId,
          qty: this.getProduct.minQtyForPurchase,
          productName: this.product.name,
        });
      } else {
        this.added = true;
        await this.addToCart({
          productId: this.product.id,
          productPricingId: this.product.productPricingId,
        })
          .then(() => {
            this.setEmptyCart(false);
            Message({
              type: "success",
              showClose: true,
              message: `The product ${this.product.name} successfully added into the cart.`,
            });
            setTimeout(() => {
              this.added = false;
            }, 1000);
          })
          .catch((e) => {
            Message({
              type: "error",
              showClose: true,
              message: e,
            });
          });
      }
    },
    wholesaleTierPricing() {
      if (this.product.productPricing !== undefined) {
        const data = this.product.productPricing.filter((pricing) => {
          if (pricing.id === this.getWholesaleTierPricingId) {
            return pricing;
          }
          return null;
        });
        if (data.length > 0) {
          return data[0].finalPrice.toFixed(2);
        }
        return 0;
      }
      return 0;
    },
    kitPricing() {
      return 0;
    },
    shippingPricing() {
      return 5;
    },
    anticipatedCost() {
      return (this.productPricing - (this.wholesaleTierPricing() + this.kitPricing() + this.shippingPricing())).toFixed(
        2
      );
    },
    routeToProductDetails() {
      this.$router
        .push({
          path: `/marketplace/${this.product.categorySlug}/${this.product.vin}/${this.products.slug}`,
        })
        .catch();
    },
    productRoute(p) {
      const { vin, slug, categorySlug } = p;
      return {
        name: "Product",
        params: { vin, slug, categorySlug },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.store-tag {
  margin-left: auto;
  padding: 0px 5px;
}
.checkbox {
  width: 25px;
  height: 15px;
  // color: red;
  margin-bottom: 10px;
  cursor: pointer;
}
.sidebar-btn {
  margin-left: auto;
  padding: 8px;
}
.product-card {
  padding: 5px;
}
.image-wrapper {
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-right: 16px;
}
.image {
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
}
.placeholder {
  height: 100px;
  width: 100px;
  background: $light-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}
.placeholder-image {
  width: 75px;
}
.info-top {
  margin-top: 10px;
  overflow: hidden;
  height: 35px;

  .out-of-stock-text {
    font-weight: 600;
    color: #f56c6c;
    font-size: 9px;
  }
}
.name {
  font-size: 0.8em;
  color: $simpatra-blue;
  font-weight: 600;
  height: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.out-of-stock {
    color: #374458b3;
  }
}
.info-bottom {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.price {
  color: $simpatra-blue;
  font-size: 0.8em;
  position: absolute;
}
.add-to-cart {
  color: $simpatra-orange;
  background: none;
  border: none;
  font-size: 0.8em;
  padding: 10px;
  margin: -10px;
  &:hover {
    cursor: pointer;
    background-color: #feefea;
    border-radius: 4px;
  }
  &:focus {
    outline: none;
  }
}
.price-item {
  display: flex;
  justify-content: space-between;
  color: #000;
}
.product-card-wrapper {
  width: 100%;
}
.link {
  display: flex;
  width: 100%;
  // justify-content: space-between;
}
.no-link {
  display: flex;
  align-items: center;
  height: 100%;
}
.product-list-view .card-content .info-container {
  width: 78%;
  margin: 0 16px;
}
.product-card--prescription-info {
  font-size: 12px;
  opacity: 0.8;
  width: 80%;
  text-align: center;
}

@media screen and (max-width: 425px) {
  .no-link {
    height: 42%;

    .add-to-cart-list {
      width: 100% !important;
    }
  }

  .info-container {
    width: 60% !important;
  }
}
</style>
