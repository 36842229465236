<template>
  <div
    v-loading="loading"
    class="purchase-parent"
  >
    <!-- <p class="text">
      +2.87 Shipping and fee details.
    </p> -->
    <p
      v-if="getProduct.inStock"
      class="text"
    >
      - In stock
    </p>
    <p
      v-if="!getProduct.inStock"
      class="text"
    >
      - Out of stock
    </p>
    <p class="text">
      - Cancel anytime
    </p>
    <!-- <div
      class="arrive-item">
      Arrival: <span class="arrive-date">Aug 24 - Aug 31</span>
    </div> -->
    <div class="qty-details">
      <div class="qty">
        <h4>Quantity</h4>
        <div
          class="qty-input-container"
        >
          <i
            class="qty-update el-icon-remove"
            @click="removeOne"
          />
          <BaseNumberInput v-model.number="qty" />
          <i
            class="qty-update el-icon-circle-plus"
            @click="addOne"
          />
        </div>
      </div>
      <el-divider direction="vertical" />
      <div class="total">
        <h4>
          Total
        </h4>
        <h3 class="price">
          ${{ (qty * product.price).toFixed(2) }}
        </h3>
      </div>
    </div>
    <div
      v-if="success"
      class="alt"
    >
      <el-alert
        title="Item successfully added to cart!"
        type="success"
        center
        show-icon
        @close="closeAlert"
      />
    </div>
    <div
      v-if="minQty"
      class="alt"
    >
      <el-alert
        title="This product has a minimum order quantity of 10"
        type="warning"
        center
        show-icon
        @close="closeAlert"
      />
    </div>
    <div class="btns">
      <BaseButton
        class="add-cart-btn"
        type="secondary"
        :disabled="minQty"
        @click="addToCart"
      >
        ADD TO CART
      </BaseButton>
      <BaseButton
        class="buy-now-btn"
        type="cta"
        :disabled="minQty"
        @click="checkout"
      >
        BUY NOW
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/common/BaseButton.vue';
import BaseNumberInput from '@/components/common/BaseNumberInput.vue';

export default {
  name: 'ProductOneTimePurchase',
  components: {
    BaseButton,
    BaseNumberInput,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      qty: 1,
      loading: false,
      success: false,
    };
  },
  computed: {
    ...mapGetters({
      getSelectedCurrentLocationId: 'getSelectedCurrentLocationId',
      getProduct: 'marketplaceProduct/getProduct',
    }),
    minQty() {
      if (this.qty < this.getProduct.minQtyForPurchase && this.getProduct.inStock) {
        return true;
      }
      return false;
    },
  },
  async created() {
    this.qty = this.product.qty;
    await this.fetchProductById(this.product.productId);
  },
  methods: {
    ...mapActions({
      addItemToCart: 'marketplaceProduct/addItemToCart',
      fetchProductById: 'marketplaceProduct/fetchProductById',
      setEmptyCart: 'setEmptyCart',
    }),
    closeAlert() {
      this.success = false;
    },
    async addToCart() {
      this.loading = true;
      const data = {
        productId: this.product.productId,
        productPricingId: this.product.productPricingId,
        qty: this.qty,
        locationId: this.getSelectedCurrentLocationId,
      };
      await this.addItemToCart(data);
      await this.setEmptyCart(false);
      this.success = true;
      this.loading = false;
    },
    checkout() {
      this.visible = false;
      this.$emit('closeProductDrawer');
      this.$router.push({ name: 'NewCheckout', params: { locationId: this.getSelectedCurrentLocationId } }).catch(() => {});
    },
    removeOne() {
      if (this.qty >= 2) {
        this.qty -= 1;
      }
    },
    addOne() {
      this.qty += 1;
    },
  },
};
</script>

<style  lang="scss" scoped>
::v-deep .el-divider--vertical {
  height: auto;
  margin-left: 30px;
  margin-right: 30px;
}
.alt {
  margin-top:20px;
  margin-bottom: 20px;
}
.text {
  margin: 0px;
  color: black;
}
.price {
  margin-top: 20px;
  font-size: 1.5em;
  font-weight: 600;
}
.qty-update {
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f05a28;
  opacity: 5;
  transition: all .2s ease;
  margin: 0px 5px;
}
.qty-input-container {
  display: flex;
  margin-top: 20px;
}
.qty-details {
  margin-top: 40px;
  display: flex;
  margin-bottom: 50px;
}
.add-cart-btn {
  width: 220px;
  margin-right:10px;
}
.buy-now-btn {
  width: 220px;
  margin-left: 10px;
}
.sub-btn {
  width: 400px;
  margin-left: 35px;
}
.arrive-item {
    margin-top: 15px;
    padding: 5px 10px;
    background: $grey;
    border-radius: 50px;
    display: inline-block;
    font-size: .8em;
    border: 1px solid #d4d2d2;
}
.arrive-date {
  font-weight: 600;
}
</style>
